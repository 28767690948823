import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  // ToggleButton,
  // ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Color from "../../color/color";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useForm } from "react-hook-form";
import dayjs, { Dayjs } from "dayjs";
import { MobileDatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { DateRangePicker } from "@mui/x-date-pickers-pro/DateRangePicker";
import { DateRange } from "@mui/x-date-pickers-pro";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { useNavigate } from "react-router-dom";

// import "react-phone-number-input/style.css";
// import PhoneInput from "react-phone-number-input";
// import { Margin } from "@mui/icons-material";
// import { formatPhoneNumberIntl } from "react-phone-number-input";
// import Input from "react-phone-number-input/input";

import "../../index.css";
import CloseIcon from "@mui/icons-material/Close";
// import toast from "react-hot-toast";

function InstantRide() {
  const navigate = useNavigate();
  const [alignment] = React.useState("instantRide");
  const [selectedSeats, setSelectedSeats] = useState(1);
  const [luggage, setLuggage] = useState("");
  const Seats = [1, 2, 3, 4];
  const Luggage = ["SMALL", "LARGE", "MEDIUM"];
  const [
    selectedRecurringRideScheduleDate,
    setRecurringRideScheduleSelectedDate,
  ] = useState<DateRange<Dayjs>>([dayjs(), dayjs()]);
  const [selectedScheduleDate, setScheduleSelectedDate] =
    useState<Dayjs | null>(null);
  const [selectedScheduleTime, setScheduleSelectedTime] =
    useState<dayjs.Dayjs | null>(null);
  const [selectedReachTime, setScheduleReachTime] =
    useState<dayjs.Dayjs | null>(null);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    setError,
  } = useForm();

  const [pickupAddressField, setPickupAddressField] = useState(null);
  const [dropAddressField, setDropAddressField] = useState(null);

  const autoCompleteRefPickup = useRef<HTMLInputElement>(null);
  const autoCompleteRefDrop = useRef<HTMLInputElement>(null);
  let autocompletePickup: google.maps.places.Autocomplete | null = null;
  let autocompleteDrop: google.maps.places.Autocomplete | null = null;

  // Initialize Google Autocomplete for Pickup Address
  const handleScriptLoad = () => {
    autocompletePickup = new window.google.maps.places.Autocomplete(
      autoCompleteRefPickup.current!,
      {
        types: ["geocode"],
        componentRestrictions: { country: "CA" }, // Restrict to Canada and Ontario
      }
    );
    autocompletePickup?.addListener("place_changed", handlePlaceSelectPickup);

    autocompleteDrop = new window.google.maps.places.Autocomplete(
      autoCompleteRefDrop.current!,
      {
        types: ["geocode"],
        componentRestrictions: { country: "CA" }, // Restrict to Canada and Ontario
      }
    );
    autocompleteDrop?.addListener("place_changed", handlePlaceSelectDrop);
  };

  // Handle place selection for Pickup Address
  const handlePlaceSelectPickup = () => {
    const place = autocompletePickup?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const addressFild: any = {
        address: address,
        latitude: latitude,
        longitude: longitude,
      };

      setPickupAddressField(addressFild);
    }
  };

  // Handle place selection for Drop Address
  const handlePlaceSelectDrop = () => {
    const place = autocompleteDrop?.getPlace();
    if (place && place.geometry && place.geometry.location) {
      const address = place.formatted_address;
      const latitude = place.geometry.location.lat();
      const longitude = place.geometry.location.lng();
      const addressFild: any = {
        address: address,
        latitude: latitude,
        longitude: longitude,
      };

      setDropAddressField(addressFild);
    }
  };

  // Load Google Autocomplete script
  useEffect(() => {
    if (!window.google) {
      const script = document.createElement("script");
      script.type = "text/javascript";
      // script.src = `https://maps.googleapis.com/maps/api/js?key=YOUR_API_KEY&libraries=places`;
      script.id = "google-autocomplete";
      document.body.appendChild(script);
      script.onload = handleScriptLoad;
    } else {
      handleScriptLoad();
    }
  }, []);

  const handleRecurringRideScheduleDateChange = (value: DateRange<Dayjs>) => {
    setRecurringRideScheduleSelectedDate(value);
  };
  const handleScheduleTimeChange = (time: any) => {
    setScheduleSelectedTime(time);
  };

  const handleReachTimeChange = (time: any) => {
    setScheduleReachTime(time);
  };

  const handleScheduleDateChange = (date: any) => {
    setScheduleSelectedDate(date);
  };

  const onSubmit = async (data: any) => {
    const plainDate = selectedScheduleDate
      ? selectedScheduleDate.toDate()
      : null;
    const plainTime = selectedScheduleTime
      ? selectedScheduleTime.format("HH:mm")
      : null;
    const ReachTime = selectedReachTime
      ? selectedReachTime.format("HH:mm")
      : null;

    const [startDate, endDate] = selectedRecurringRideScheduleDate;
    const startTimestamp = startDate ? startDate.toDate() : null;
    const endTimestamp = endDate ? endDate.toDate() : null;

    let formData = {};
    if (alignment != "recurringRide") {
      formData = {
        name: data.name,
        email: data.email,
        contactNumber: data.contactNumber,
        pickupAddress: pickupAddressField,
        dropAddress: dropAddressField,
        seats: data.seats,
        luggage: data.luggage,
        scheduleDate: plainDate,
        ScheduleTime: plainTime,
        note: data.note,
        reachTime: ReachTime,
        instantRide: true,
      };
    } else {
      formData = {
        pickupAddress: pickupAddressField,
        dropAddress: dropAddressField,
        seats: data.seats,
        luggage: data.luggage,
        ScheduleTime: plainTime,
        note: data.note,
        reachTime: ReachTime,
        startDate: startTimestamp,
        endDate: endTimestamp,
        instantRide: false,
      };
    }

    try {
      await firebase.firestore().collection("gobotaUser").add(formData);
      reset();
      // toast.success(
      //   "Your ride has been scheduled successfully! Our support executive will contact you shortly. 🚗🕒",
      //   {
      //     style: {
      //       minWidth: "500px",
      //     },
      //     duration: 5000,
      //   }
      // );
      navigate("/ThankYou");
    } catch (error) {
      console.error("Error adding document: ", error);
    }

    // console.log("value", formData);
  };

  const handleChange = (event: any) => {
    setSelectedSeats(event.target.value);
    setError("seats", { type: "", message: "" });
  };

  const handleLuggageChange = (event: any) => {
    setLuggage(event.target.value as string);
    setError("luggage", { type: "", message: "" });
  };

  const goToHome = () => {
    navigate("/");
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          padding: "0",
        }}
      >
        <Container maxWidth="xl" sx={{ padding: "0" }}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  mt: "40px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <Box></Box>
                <Typography
                  sx={{
                    fontSize: { xs: "20px", md: "25px" },
                    fontFamily: "Poppins",
                    fontWeight: { xs: 600, md: 700 },
                  }}
                >
                  Schedule Your Ride
                </Typography>
                <CloseIcon
                  fontSize="large"
                  onClick={goToHome}
                  sx={{ cursor: "pointer", paddingRight: "15px" }}
                />
              </Box>
              {/* <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  mt={3}
                >
                  <ToggleButtonGroup
                    color="primary"
                    value={alignment}
                    exclusive
                    aria-label="Platform"
                    sx={{
                      background: Color.lightGray,
                      padding: "5px !important",
                      border: "none !important",
                      borderRadius: "22px !important",
                      gap: 2,
                    }}
                  >
                    <ToggleButton
                      value="instantRide"
                      onClick={() => setAlignment("instantRide")}
                      className={
                        alignment === "instantRide" ? "activeButton" : ""
                      }
                      sx={{
                        "&.activeButton": {
                          backgroundColor: Color.primary,
                          fontWeight: "400",
                          color: "white",
                          "& .MuiTypography-root": {
                            color: "white",
                            fontWeight: "400",
                          },
                          borderRadius: "20px",
                        },
                        "&.activeButton:hover": {
                          backgroundColor: Color.primary,
                          color: "#000000",
                          fontWeight: "800 !important",
                          borderRadius: "20px !important",
                        },
                        border: "none",
                        width: "150px",
                        borderRadius: "20px !important",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          textTransform: "none",
                          letterSpacing: 0.3,
                          fontWeight:
                            alignment == "instantRide"
                              ? "700 !important"
                              : "600 !important",
                        }}
                      >
                        Instant Ride
                      </Typography>
                    </ToggleButton>
                    <ToggleButton
                      value="recurringRide"
                      onClick={() => setAlignment("recurringRide")}
                      className={
                        alignment === "recurringRide" ? "activeButton" : ""
                      }
                      sx={{
                        "&.activeButton": {
                          backgroundColor: Color.primary,
                          color: "white",
  
                          "& .MuiTypography-root": {
                            color: "white",
                          },
                          borderRadius: "20px",
                        },
                        "&.activeButton:hover": {
                          backgroundColor: Color.primary,
                          color: "white",
                          borderRadius: "20px !important",
                        },
                        border: "none",
                        width: "150px",
                        borderRadius: "20px !important",
                      }}
                    >
                      <Typography
                        variant="body2"
                        sx={{
                          display: "flex",
                          textTransform: "none",
                          letterSpacing: 0.3,
                          fontWeight:
                            alignment == "recurringRide"
                              ? "700 !important"
                              : "600 !important",
                        }}
                      >
                        Recurring Ride
                      </Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box> */}
            </Grid>
          </Grid>

          <Box>
            <form onSubmit={handleSubmit(onSubmit)}>
              <Grid item xs={12} md={12} marginTop={2}>
                <Box
                  rowGap={3}
                  px={10}
                  sx={{
                    paddingLeft: "69px",
                  }}
                  py={4}
                  columnGap={3}
                  display="grid"
                  gridTemplateColumns={{
                    xs: "repeat(1, 1fr)",
                    sm: "repeat(2, 1fr)",
                  }}
                >
                  <Box>
                    <TextField
                      fullWidth
                      label="Name"
                      // name="pickupAddress"
                      {...register("name", {
                        required: true,
                      })}
                      placeholder="Enter Your Name"
                    />
                    {errors.name && errors.name.type === "required" && (
                      <p className="errorMsg" style={{ color: "red" }}>
                        Name is required
                      </p>
                    )}
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      label="Email"
                      // name="pickupAddress"
                      {...register("email", {
                        required: true,
                        pattern: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                      })}
                      placeholder="Email"
                    />
                    {errors.email && errors.email.type === "required" && (
                      <p className="errorMsg" style={{ color: "red" }}>
                        Email is required.
                      </p>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <p className="errorMsg" style={{ color: "red" }}>
                        Email is not valid.
                      </p>
                    )}
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Pickup Address"
                      inputRef={autoCompleteRefPickup}
                      {...register("pickupAddress", {
                        required: true,
                      })}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Pickup Address Here"
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    {errors.pickupAddress &&
                      errors.pickupAddress.type === "required" && (
                        <p className="errorMsg" style={{ color: "red" }}>
                          Pickup Address is required
                        </p>
                      )}
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      label="Drop Address"
                      inputRef={autoCompleteRefDrop}
                      {...register("dropAddress", {
                        required: true,
                      })}
                      onKeyDown={(e) => {
                        if (e.key === "Enter") {
                          e.preventDefault();
                        }
                      }}
                      placeholder="Drop Address Here"
                      InputProps={{
                        endAdornment: (
                          <IconButton>
                            <LocationOnIcon />
                          </IconButton>
                        ),
                      }}
                    />
                    {errors.dropAddress &&
                      errors.dropAddress.type === "required" && (
                        <p className="errorMsg" style={{ color: "red" }}>
                          Drop Address is required
                        </p>
                      )}
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      type="tel" // Change type to "tel" for phone number input
                      label="Contact Number"
                      {...register("contactNumber", {
                        required: true,
                        pattern: /^\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
                      })}
                      placeholder="e.g., (555) 123-4567"
                      onInput={(e: any) => {
                        if (!parseInt(e.target.value)) {
                          e.target.value = "";
                        } else {
                          e.target.value = Math.max(0, parseInt(e.target.value))
                            ?.toString()
                            ?.slice(0, 10);
                        }
                      }}
                    />
                    {errors.contactNumber &&
                      errors.contactNumber.type === "required" && (
                        <p className="errorMsg" style={{ color: "red" }}>
                          Contact Number is required
                        </p>
                      )}
                    {errors.contactNumber &&
                      errors.contactNumber.type === "pattern" && (
                        <p className="errorMsg" style={{ color: "red" }}>
                          Please enter a valid Canadian phone number
                        </p>
                      )}
                  </Box>

                  {alignment == "recurringRide" ? (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DateRangePicker"]}>
                          <DemoItem component="DateRangePicker">
                            <DateRangePicker
                              localeText={{
                                start: "Schedule Date Start",
                                end: "Schedule Date End",
                              }}
                              value={selectedRecurringRideScheduleDate}
                              onChange={handleRecurringRideScheduleDateChange}
                            />
                          </DemoItem>
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  ) : (
                    <Box>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["DatePicker"]}>
                          <MobileDatePicker
                            label="Schedule Date"
                            sx={{ width: "100%" }}
                            value={selectedScheduleDate}
                            onChange={handleScheduleDateChange}
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Box>
                  )}

                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <MobileTimePicker
                          sx={{ width: "100%" }}
                          label="Schedule Time"
                          value={selectedScheduleTime}
                          onChange={handleScheduleTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>

                  <Box>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["TimePicker"]}>
                        <MobileTimePicker
                          label="Time to Reach"
                          sx={{ width: "100%" }}
                          value={selectedReachTime}
                          onChange={handleReachTimeChange}
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="seatsRequired">Seats Required</InputLabel>
                      <Select
                        labelId="seatsRequired"
                        id="seats"
                        {...register("seats", {
                          required: true,
                        })}
                        value={selectedSeats}
                        onChange={handleChange}
                        input={<OutlinedInput label="Seats Required" />}
                      >
                        {Seats.map((seats) => (
                          <MenuItem key={seats} value={seats}>
                            {seats}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    {errors.seats && errors.seats.type === "required" && (
                      <p className="errorMsg" style={{ color: "red" }}>
                        Seats is required
                      </p>
                    )}
                  </Box>

                  <Box>
                    <FormControl fullWidth>
                      <InputLabel id="specifyLuggageType">
                        Specify Luggage Type
                      </InputLabel>
                      <Select
                        labelId="specifyLuggageType"
                        id="luggage"
                        {...register("luggage")} // Register with react-hook-form
                        value={luggage}
                        onChange={handleLuggageChange}
                        input={<OutlinedInput label="Specify Luggage Type" />}
                      >
                        {Luggage.map((luggageType) => (
                          <MenuItem key={luggageType} value={luggageType}>
                            {luggageType}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>

                  <Box>
                    <TextField
                      fullWidth
                      label="Note"
                      // name="note"
                      {...register("note")}
                      placeholder="Note....."
                    />
                  </Box>
                </Box>
              </Grid>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Grid
                  item
                  xs={12}
                  md={6}
                  sx={{
                    display: "flex",
                    flexDirection: { xs: "column", md: "row" },
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  {/* <Button
                      type="submit"
                      onClick={goToHome}
                      sx={{
                        backgroundColor: Color.black,
                        color: "white",
                        borderRadius: 2,
                        height: "45px",
                        width: "180px",
                        ":hover": {
                          backgroundColor: Color.black,
                          color: "white",
                        },
                        marginRight: { xs: "0px", md: "10px" },
                        marginBottom: { xs: "10px", md: "0px" },
                      }}
                    >
                      Cancel
                    </Button> */}
                  <Button
                    type="submit"
                    sx={{
                      backgroundColor: Color.primary,
                      color: "white",
                      borderRadius: 2,
                      height: "45px",
                      width: "180px",
                      ":hover": {
                        backgroundColor: "orange",
                        color: "white",
                      },
                      marginRight: { xs: "0px", md: "10px" },
                      marginBottom: { xs: "10px", md: "0px" },
                    }}
                  >
                    Confirm Ride
                  </Button>
                </Grid>
              </Box>
            </form>
          </Box>
        </Container>
      </Box>
    </>
  );
}

export default InstantRide;
