import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  Typography,
} from "@mui/material";
import Color from "../color/color";
// import { ArrowForward } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { Icon } from "@iconify-icon/react";

function Home() {
  const navigate = useNavigate();
  const handleRideSubmit = () => {
    navigate("/instant-ride");
  };
  const handleDriveSubmit = () => {
    navigate("/InstantRideDriver");
  };
  return (
    <>
      <Box
        sx={{
          backgroundImage: {
            xs: 'url("/static/images/mobile.png")',
            md: 'url("/static/images/background.png")',
          },
          backgroundRepeat: "no-repeat",
          minHeight: "96vh",
          width: "100%",
          backgroundSize: "100% 100%",
          display: "flex",
          position: "relative",
        }}
      >
        <Container maxWidth="lg">
          <Grid
            container
            spacing={3}
            // pt={18}
            sx={{
              marginTop: {
                sm: "10px",
                xs: "10px",
                md: "60px",
                lg: "70px",
                xl: "80px",
                paddingLeft: "0",
              },
              padding: "0 30px",
            }}
          >
            <Grid item xs={12} md={6}>
              <Box>
                <Typography
                  sx={{
                    color: "#ffffff",
                    fontWeight: { xs: 400, md: 700 },
                    fontSize: { xs: "40px", md: "60px" },
                    fontFamily: "Karantina",
                    letterSpacing: { xs: 0, md: 2 },
                  }}
                >
                  Get Where You Need{" "}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontSize: { xs: "40px", md: "60px" },
                      fontWeight: { xs: 400, md: 700 },
                      fontFamily: "Karantina",
                      letterSpacing: { xs: 0, md: 2 },
                    }}
                  >
                    to Go,
                  </Typography>
                  <Box
                    ml={1}
                    sx={{
                      alignContent: "center",
                      justifyItems: "center",
                      height: { xs: 40, md: 55 },
                      width: { xs: 120, md: 180 },
                      background: "orange",
                      borderRadius: 1.5,
                    }}
                  >
                    <Typography
                      textAlign="center"
                      alignItems="center"
                      // variant="h4"
                      sx={{
                        color: "#ffffff",
                        fontWeight: { xs: 400, md: 700 },
                        fontSize: { xs: "28px", md: "35px" },
                        fontFamily: "Karantina",
                        letterSpacing: { xs: 1, md: 2 },
                      }}
                    >
                      Hassle-Free
                    </Typography>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography
                    sx={{
                      color: "#ffffff",
                      fontFamily: "Poppins",
                      pr: "10px",
                    }}
                  >
                    Whether you're a rider looking for seamless transportation
                    or a driver ready to turn miles into money, our platform has
                    you covered.
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: { xs: "column", md: "row" },
                  alignItems: "flex-start",
                  gap: 1.5,
                }}
                mt={5}
              >
                <Box>
                  {/* Half-white rounded box containing black arrow symbol */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: "10px 10px 10px 10px",
                      // padding: "10px",
                    }}
                  >
                    <IconButton sx={{ color: "black" }}>
                      <Icon
                        icon="majesticons:user-box-line"
                        style={{
                          color: Color.black,

                          fontSize: "25px",
                        }}
                      />
                    </IconButton>
                    <Button
                      type="submit"
                      onClick={handleRideSubmit}
                      sx={{
                        backgroundColor: Color.primary,
                        color: "white",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0 10px 10px 0",
                        ":hover": {
                          backgroundColor: "orange",
                          color: "white",
                        },
                        padding: { xs: "7px", md: "10px" },
                      }}
                    >
                      READY TO RIDE
                    </Button>
                  </Box>
                </Box>
                <Box>
                  {/* Half-white rounded box containing black arrow symbol */}
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      backgroundColor: "white",
                      borderRadius: "10px 10px 10px 10px",
                      // padding: "10px",
                    }}
                  >
                    <IconButton sx={{ color: "black" }}>
                      <Icon
                        icon="mdi:steering"
                        style={{
                          color: Color.black,
                          // paddingLeft: "20px",
                          fontSize: "25px",
                          fontWeight: "500",
                        }}
                      />
                    </IconButton>
                    <Button
                      type="submit"
                      onClick={handleDriveSubmit}
                      sx={{
                        backgroundColor: Color.primary,
                        color: "white",
                        fontSize: "15px",
                        fontWeight: 600,
                        borderRadius: "0 10px 10px 0",
                        ":hover": {
                          backgroundColor: "orange",
                          color: "white",
                        },
                        padding: { xs: "7px", md: "10px" },
                      }}
                    >
                      READY TO DRIVE
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box
                component={"img"}
                src="/static/images/Car.png"
                sx={{
                  position: "absolute",
                  width: { xs: "60%", sm: "60%", md: "50%", xl: "40%" },
                  right: { xs: 0, sm: 2, md: 0 },
                  bottom: {
                    xs: "20px",
                    sm: "20px",
                    md: "19px",
                    lg: "20px",
                    xl: "21px",
                  },
                  objectFit: "cover",
                }}
              />
              {/* <img src="src/assets/Car.png" alt="Car" width={"30%"} /> */}
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Box
        sx={{
          minHeight: "4vh",
          width: "100%",
          backgroundColor: Color.black,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "row",
        }}
      >
        <CopyrightIcon
          sx={{ color: Color.white, fontSize: { xs: "14px", md: "18px" } }}
        />
        <Typography
          sx={{
            color: Color.white,
            fontSize: { xs: "10px", md: "12px" },
            paddingLeft: "5px",
          }}
        >
          2024 All Right Reserved.
        </Typography>
      </Box>
    </>
  );
}

export default Home;
