import { Box, Button, Container, Grid, Typography } from "@mui/material";
import Color from "../../color/color";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
function ThankYou() {
  const navigate = useNavigate();
  const [countdown, setCountdown] = useState(7);
  const goToHome = () => {
    navigate("/");
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prevSeconds) => prevSeconds - 1);
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (countdown === 0) {
      goToHome();
    }
  }, [countdown]);

  const handleButtonClick = () => {
    goToHome();
  };
  return (
    <>
      <Box
        sx={{
          minHeight: "100vh",
          width: "100%",
          backgroundSize: "100% 100%",
          display: "flex",
        }}
      >
        <Container maxWidth="xl">
          <Grid container pt={25}>
            <Grid item xs={12} md={12}>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Box
                  component={"img"}
                  src="/static/images/star.png"
                  sx={{
                    height: { xs: "90px", md: "110px" },
                  }}
                />
                <Typography
                  variant="h1"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: "50px", md: "80px" },
                    fontFamily: "Poppins",
                    letterSpacing: 1,
                    color: Color.primary,
                  }}
                >
                  Thank You{" "}
                </Typography>
                {/* <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 500,
                    fontSize: { xs: "30px", md: "40px" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    // letterSpacing: 2,
                  }}
                >
                  Your ride has been scheduled successfully!
                </Typography> */}
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 300,
                    fontSize: { xs: "20px", md: "30px" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    // letterSpacing: 2,
                    mt: "20px",
                  }}
                >
                  Our support executive will contact you shortly.
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  mt={10}
                >
                  <Button
                    type="submit"
                    onClick={handleButtonClick}
                    sx={{
                      backgroundColor: Color.primary,
                      color: "white",
                      borderRadius: 2,
                      height: "45px",
                      width: "180px",
                      ":hover": {
                        backgroundColor: "orange",
                        color: "white",
                      },
                    }}
                  >
                    Go To Home
                  </Button>
                </Box>
                <Typography
                  sx={{
                    // fontSize: { xs: "20px", md: "30px" },
                    fontFamily: "Poppins",
                    textAlign: "center",
                    justifyContent: "center",
                    alignItems: "center",
                    // letterSpacing: 2,
                  }}
                  mt={3}
                >
                  {`Redirecting in ${countdown} seconds`}
                </Typography>
                <Box></Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
}

export default ThankYou;
