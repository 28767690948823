import "./App.css";
import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";

import InstantRide from "./pages/instantRide";
import InstantRideDriver from "./pages/instantRideDriver";
import { Toaster } from "react-hot-toast";
import ThankYou from "./pages/thankyou";
function App() {
  const firebaseConfig = {
    apiKey: "AIzaSyAvgbn6r5z0HYu3PnuY2UypProIGdzNbBA",
    authDomain: "gobota-64214.firebaseapp.com",
    projectId: "gobota-64214",
    storageBucket: "gobota-64214.appspot.com",
    messagingSenderId: "173011758927",
    appId: "1:173011758927:web:e99c53e623914c455c1f3a",
    measurementId: "G-G3F73EHJY7",
  }; // gobota database

  // Initialize Firebase
  if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
  }

  return (
    <>
      <Toaster position="top-right" reverseOrder={false} />
      <Routes>
        <Route index element={<Home />} />

        <Route path="/instant-ride" element={<InstantRide />} />
        <Route path="/InstantRideDriver" element={<InstantRideDriver />} />
        <Route path="/ThankYou" element={<ThankYou />} />
        <Route path="*" element={<Home />} />
      </Routes>
    </>
  );
}

export default App;
